import { ThemeService } from './../../../services/theme/theme.service';
import { Component } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	constructor(private themeService: ThemeService) {}

	get logo(): string {
		return this.themeService.logo;
	}
}
