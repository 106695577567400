import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdsAlertModule, IdsConfigModule } from '@ids/angular';
import { Segments } from '@ids/tools';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { CharonHttpService, CharonModule } from '@quickweb/connect-angular';
import { SeaHTTPModule } from './modules/sea-http/sea-http.module';
import { ErrorHandlingService } from './services/error-handling/error-handling.service';

@NgModule({
	declarations: [AppComponent],
	imports: [			
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		PagesModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		IdsAlertModule,
		IdsConfigModule.forRoot({
			production: environment.production,
			segment: Segments.Varejo,
		}),
		CharonModule.withConfig({
			entryPoint: environment.charon.entrypoint,
			payloadCryptography: {
				initSessionUrl: environment.charon.cryptographyUrl,
			},
			httpOptions: {
				headers: new HttpHeaders({
					'x-itau-apikey': environment.charon.itauApiKey,
				}),
			},
			provider: CharonHttpService,
		}),
		SeaHTTPModule.forRoot({
			SEAGateway: {
				baseUrl: environment.bff.baseUrl,
				relationPathMap: {
					createAuthorizationCode: 'sea/register-password',
					createUser: 'sea/create-user',
				},
			},
			charonId: 'qvjzobwp',
		}),
	],
	providers: [ErrorHandlingService],
	bootstrap: [AppComponent],
})
export class AppModule {}
