import { Observable } from 'rxjs';

export interface SEARequest {
	relation: string;
	body?: Record<string, any>;
	headers?: Record<string, any>;
	pathParams?: Record<string, any>;
	queryParams?: Record<string, any>;
}

export abstract class SEAHttpClient {
	constructor() {}

	abstract get<Response>(request: SEARequest): Observable<Response>;
	abstract post<Response>(request: SEARequest): Observable<Response>;
	abstract put<Response>(request: SEARequest): Observable<Response>;
	abstract delete<Response>(request: SEARequest): Observable<Response>;
}
