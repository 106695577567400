import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FeatureToggleService {
	private appFeatureToggles: Record<string, boolean> = {};

	constructor() {
		this.appFeatureToggles = environment.featureToggles;
	}

	isFeatureEnabled(featureName: string) {
		return this.appFeatureToggles[featureName] ?? false;
	}
}
