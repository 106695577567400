import { Inject, Injectable } from '@angular/core';
import { Observable, map, shareReplay, switchMap, takeLast } from 'rxjs';
import { CharonService } from '@quickweb/connect-angular';
import { SEARequest, SEAHttpClient } from '../sea-http-client.service';
import { CHARON_ID } from '../injection-tokens';
import { v4 } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class CharonHttpAdapter extends SEAHttpClient {
	$init: Observable<any>;

	constructor(
		private charon: CharonService,
		@Inject(CHARON_ID) private charonId: string
	) {
		super()
	}

	get<Response>(request: SEARequest): Observable<Response> {
		return this.requestCharon(() => this.charon.get(request.relation, {
			headers: this.addHeaders(request),
			withCredentials: true,
			charonID: this.charonId,
		}));
	}

	post<Response>(request: SEARequest): Observable<Response> {
		return this.requestCharon(() => this.charon.post(request.relation, request.body, {
			headers: this.addHeaders(request),
			withCredentials: true,
			charonID: this.charonId,
		}))
	}

	put<Response>(request: SEARequest): Observable<Response> {
		return this.requestCharon(() => this.charon.put(request.relation, request.body, {
			headers: this.addHeaders(request),
			withCredentials: true,
			charonID: this.charonId,
		}));
	}

	delete<Response>(request: SEARequest): Observable<Response> {
		return this.requestCharon(() => this.charon.delete(request.relation, {
			headers: this.addHeaders(request),
			withCredentials: true,
			charonID: this.charonId,
		}));
	}

  private requestCharon(requestCallback: () => Observable<any>) {
    return this.initialize().pipe(
      switchMap(requestCallback),
      takeLast(1),
      map((res: any) => res),
    );
  }

	private initialize() {
		if (!this.$init) {
			this.$init = this.charon.initialize({ charonID: this.charonId }).pipe(shareReplay(1))
		}
		return this.$init;
	}

	private addHeaders(request: SEARequest) {
		return {
			...request.headers,
			...this.mountUriParams(request),
			'accept-Language': 'pt-BR',
			'x-itau-correlationid': v4().toString()
		};
	}

	private mountUriParams(request: SEARequest) {
		const params = { ...request.pathParams, ...request.queryParams };
		if (this.hasUriParams(params)) {
			const encodedParams = this.encodeParams(params);
			return {
				'x-charon-params': encodedParams,
			};
		}
		return undefined;
	}

	private hasUriParams(params: Record<string, any>) {
		return Object.keys(params).length > 0;
	}

	private encodeParams(params: Record<string, any>) {
		const serializedParams = JSON.stringify(params);
		return btoa(serializedParams);
	}
}
