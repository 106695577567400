import { Injectable } from '@angular/core';

type Theme = {
	logo: string;
	cssClass: string;
	tenantName: string;
};

type tenantId = string;

export const TENANTS_THEMES: Record<tenantId, Theme> = {
	'0d68da2d-7910-468c-bc3d-10b345e62177': {
		tenantName: '2W ecobank',
		cssClass: 'doisw',
		logo: 'assets/logos/2w.svg',
	},
	'f20baa94-bb65-42d3-b102-5fc4e7c80514': {
		tenantName: 'Porto seguro',
		cssClass: 'porto',
		logo: 'assets/logos/porto.svg',
	},
	'36817b01-6069-4fd0-8e5e-00183feac125': {
		tenantName: 'Finpec',
		cssClass: 'finpec',
		logo: 'assets/logos/finpec.svg',
	},
};

export const DEFAULT_THEME: Theme = {
	tenantName: 'Itaú Unibanco',
	cssClass: 'base',
	logo: 'assets/logos/itau.png',
};

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private currentTenantTheme: Theme;

	get logo(): string {
		return this.currentTenantTheme.logo;
	}

	get tenantName(): string {
		return this.currentTenantTheme.tenantName;
	}

	public initialize(tenantId?: tenantId) {
		this.clearThemes();
		this.setCurrentTenantTheme(tenantId);
	}

	private clearThemes() {
		Object.values(TENANTS_THEMES).forEach((theme) => {
			document.body.classList.remove(theme.cssClass);
		});
	}

	private setCurrentTenantTheme(tenantId?: tenantId) {
		if (!tenantId) this.currentTenantTheme = DEFAULT_THEME;
		else this.currentTenantTheme = TENANTS_THEMES[tenantId] || DEFAULT_THEME;

		document.body.classList.add(this.currentTenantTheme.cssClass);
	}
}
