import { ActivatedRoute } from '@angular/router';
import { ThemeService } from './services/theme/theme.service';
import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(
		private themeService: ThemeService,
		private activatedRoute: ActivatedRoute
	) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.themeService.initialize(params?.tenantId);
		});
	}
}
