import { CharonService } from '@quickweb/connect-angular';

import { SEAGatewayHttpAdapter } from '../adapters/sea-gateway-http-adapter.service';
import { CharonHttpAdapter } from '../adapters/charon-http-adapter.service';
import { FeatureToggleService } from '@/app/services/feature-toggle/feature-toggle.service';
import { SEAGatewayConfig } from '../types';
import { HttpClient } from '@angular/common/http';

const CHARON_TOGGLE = 'charon';

export function SEAHttpClientFactory(
	httpClient: HttpClient,
	ftService: FeatureToggleService,
	charonService: CharonService,
	seaGatewayConfig: SEAGatewayConfig,
	charonId: string
) {
	if (ftService.isFeatureEnabled(CHARON_TOGGLE)) {
		return new CharonHttpAdapter(charonService, charonId);
	}

	return new SEAGatewayHttpAdapter(httpClient, seaGatewayConfig);
}
