import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SEARequest, SEAHttpClient } from '../sea-http-client.service';
import { SEAGatewayConfig } from '../types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SEA_GATEWAY_CONFIG } from '../injection-tokens';

@Injectable({
	providedIn: 'root',
})
export class SEAGatewayHttpAdapter extends SEAHttpClient {
	constructor(
		private httpClient: HttpClient,
		@Inject(SEA_GATEWAY_CONFIG)
		private gatewayConfig: SEAGatewayConfig
	) {
		super();
	}

	get<Response>(request: SEARequest): Observable<Response> {
		const { path, headers, params } = this.prepareRequest(request);
		return this.httpClient.get<Response>(path, { headers, params, withCredentials: true });
	}

	post<Response>(request: SEARequest): Observable<Response> {
		const { path, headers, body } = this.prepareRequest(request);
		return this.httpClient.post<Response>(path, body, { ...headers, withCredentials: true });
	}

	put<Response>(request: SEARequest): Observable<Response> {
		const { path, body, headers } = this.prepareRequest(request);
		return this.httpClient.put<Response>(path, body, { headers, withCredentials: true });
	}

	delete<Response>(request: SEARequest): Observable<Response> {
		const { path, headers, params } = this.prepareRequest(request);
		return this.httpClient.delete<Response>(path, { headers, params });
	}

	private prepareRequest(request: SEARequest) {
		return {
			path: this.mountPath(request.relation, request.pathParams),
			body: request.body,
			headers: this.addHeaders(request),
			withCredentials: true,
			params: request.queryParams,
		};
	}

	private mountPath(relation: string, params: Record<string, any> = {}) {
		const { relationPathMap, baseUrl } = this.gatewayConfig;
		const templatePath = relationPathMap[relation];
		const path = Object.keys(params).reduce(
			(prev, curr) => prev?.replace(`{${curr}}`, params[curr] as string) ?? '',
			templatePath
		);
		return `${baseUrl}/${path}`;
	}

	private addHeaders(request: SEARequest) {
		const content = {
			...request.headers,
		};

		const headers = new HttpHeaders();

		Object.entries(content).forEach(([key, value]) =>
			headers.append(key, value)
		);
	

		return headers;
	}
}
