import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '@/utils/password-validator';
import { SEAHttpClient } from '@/app/modules/sea-http/sea-http-client.service';
import { QueryParamsService } from '@/app/services/query-params/query-params.service';
import { HttpResponse } from '@angular/common/http';
import { ErrorHandlingService } from '@/app/services/error-handling/error-handling.service';
import { catchError, map } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-register-password',
	templateUrl: './register-password.component.html',
	styleUrls: ['./register-password.component.scss'],
})
export class RegisterPasswordComponent implements OnInit {
	form!: FormGroup;

	errorMessage?: string;
	isSubmitting = false;
	showPassword = false;
	showConfirmPassword = false;

	constructor(
		private formBuilder: FormBuilder,
		private httpClient: SEAHttpClient,
		private queryParamsService: QueryParamsService,
		private errorHandler: ErrorHandlingService,
		private cookieService: CookieService
	) {}

	ngOnInit() {
		this.buildForm();
		if (this.queryParamsService.code) {
			this.fetchCreateUser();
		} else if (this.queryParamsService.error) {
			this.showError(this.errorHandler.getErrorMessage());
		}
	}

	private buildForm() {
		this.form = this.formBuilder.group(
			{
				password: [
					'',
					[
						PasswordValidator.validateOnlyNumber,
						PasswordValidator.validateSequencialNumber,
						Validators.required,
						Validators.minLength(6),
						Validators.maxLength(6),
					],
				],
				confirmPassword: ['', Validators.compose([Validators.required])],
			},
			{
				validators: PasswordValidator.passwordMatchingValidator,
			}
		);
	}

	togglePassword() {
		this.showPassword = !this.showPassword;
	}

	toggleConfirmPassword() {
		this.showConfirmPassword = !this.showConfirmPassword;
	}

	onSubmit() {
		this.initAuthorization();
	}

	fetchCreateUser() {
		this.isSubmitting = true;
		this.fillFormWithRandomValue();

		this.httpClient
			.post<HttpResponse<{ id: string; resumeAuthUrl: string }>>({
				relation: 'createUser',
				headers: {
					'x-tenant-id': this.queryParamsService.tenantId,
				},
				body: {
					authCode: this.queryParamsService.code,
				},
			})
			.pipe(
				map((res) => {
					const URL = res.body?.resumeAuthUrl;
					if (URL) window.location.href = URL;
				}),
				catchError(async (e) => {
					this.showError(this.errorHandler.getErrorMessage(e));
					this.resetForm();
				})
			)
			.subscribe();
	}

	initAuthorization() {
		this.isSubmitting = true;

		const ticketId =
			this.queryParamsService.ticketId ??
			this.cookieService.get('sea_ticket_id');

		if (!ticketId) {
			this.showError(
				'Não foi possível criar sua assinatura pois o fluxo de credenciamento não foi iniciado corretamente.'
			);
			return;
		}

		this.httpClient
			.post<HttpResponse<{ authorizeUrl: string }>>({
				relation: 'initAuthorization',
				headers: {
					'x-tenant-id': this.queryParamsService.tenantId,
				},
				body: {
					ticketId: this.queryParamsService.ticketId,
					credential: this.form?.value?.password?.toString(),
				},
			})
			.pipe(
				map((res) => {
					const URL = res.body?.authorizeUrl;
					if (URL) window.location.href = URL;
				}),
				catchError(async (e) =>
					this.showError(
						this.errorHandler.getErrorMessage(
							e,
							'Ticket',
							this.queryParamsService.ticketId
						)
					)
				)
			)
			.subscribe();
	}

	showError(message: string) {
		this.isSubmitting = false;
		this.errorMessage = message;
	}

	resetForm() {
		this.form.reset();
		this.form.controls['password'].setErrors(null);
		this.form.controls['confirmPassword'].setErrors(null);
	}

	fillFormWithRandomValue() {
		this.form.controls['password'].patchValue('123456');
		this.form.controls['confirmPassword'].patchValue('123456');
	}
}
