import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
	static validateCapitalLetter(control: AbstractControl) {
		const regex = new RegExp(/[A-Z]/gm);
		return regex.test(control.value) ? null : { passwordInvalid: true };
	}

	static validateLowerLetter(control: AbstractControl) {
		const regex = new RegExp(/[a-z]/gm);
		return regex.test(control.value) ? null : { passwordInvalid: true };
	}

	static validateEspecialCharacter(control: AbstractControl) {
		const regex = new RegExp(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
		return regex.test(control.value) ? null : { passwordInvalid: true };
	}

	static validateNumber(control: AbstractControl) {
		const regex = new RegExp(/[0-9]/gm);
		return regex.test(control.value) ? null : { passwordInvalid: true };
	}

	static validateOnlyNumber(control: AbstractControl) {
		const regex = new RegExp("^[0-9]*$");
		return regex.test(control.value) ? null : { passwordInvalid: true };
	}

	static validateSequencialNumber(control: AbstractControl) {
		const regex = new RegExp(
			/012|123|234|345|456|567|678|789|210|321|432|543|654|765|876|987|111|222|333|444|555|666|777|888|999|000/gs
		);
		return regex.test(control.value) ? { passwordInvalid: true } : null;
	}

	static validateSequencialLetters(control: AbstractControl) {
		const regex = new RegExp(
			/abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz/gs
		);
		return regex.test(control.value) ? { passwordInvalid: true } : null;
	}

	static passwordMatchingValidator: ValidatorFn = (
		control: AbstractControl
	): ValidationErrors | null => {
		const password = control.get('password');
		const confirmPassword = control.get('confirmPassword');
		return password?.value === confirmPassword?.value
			? null
			: { noMatchedPassword: true };
	};
}
