import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class QueryParamsService {
	private queryParams: Params;
	private fragment: string | null;

	constructor(private activatedRoute: ActivatedRoute) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.queryParams = params;
		});
		this.fragment = this.activatedRoute?.snapshot?.fragment;
	}

	get tenantId() {
		return this.queryParams?.tenantId;
	}

	get code() {
		return this.fragment?.includes('code')
			? this.fragment?.split('=')?.[1]
			: this.queryParams?.code;
	}

	get ticketId() {
		return this.queryParams?.ticketId;
	}

	get error() {
		return this.fragment?.includes('error_description') || this.fragment?.includes('error')
			? this.fragment?.split('=')?.[1] : this.queryParams?.error
	}
}
