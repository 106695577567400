<section class="register-password-container color-background-primary ids-p-6">
	<ids-alert
		[show]="!!errorMessage"
		icon
		header="Erro no processo de criação da assinatura eletrônica"
		variant="error"
		(close)="errorMessage = undefined"
	>
		<div [innerHtml]="errorMessage"></div>
	</ids-alert>

	<header class="register-password-container__header">
		<h1 class="ids-title-small">crie uma assinatura eletrônica</h1>

		<p class="ids-subtitle-small ids-mt-4">
			A assinatura eletrônica será usada para realizar consultas e operações.
		</p>
	</header>

	<div class="register-password-container__content">
		<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
			<ids-form-field
				class="register-password-container__field ids-p-2 input-hide"
				label="nova assinatura eletrônica"
				errorMessage="assinatura eletrônica inválida"
				[class.input-hide]="!showPassword"
			>
				<!-- <ng-otp-input [config]="{length:6, isPasswordInput:true}"></ng-otp-input> -->
				<input
					idsInput
					type="number"
					formControlName="password"
					id="register-password-input"
					autocomplete="off"
				/>

				<ids-icon
					variant="primary"
					(click)="togglePassword()"
					id="register-password-toggle-icon"
				>
					{{ showPassword ? "olho_inativo_outline2" : "visivel_outline" }}
				</ids-icon>
			</ids-form-field>

			<ids-form-field
				class="register-password-container__field"
				label="confirme nova assinatura eletrônica"
				errorMessage="assinatura eletrônica inválida"
				[class.input-hide]="!showConfirmPassword"
			>
				<input
					idsInput
					type="number"
					formControlName="confirmPassword"
					id="register-confirm-password-input"
					autocomplete="off"
				/>

				<ids-icon
					variant="primary"
					(click)="toggleConfirmPassword()"
					id="register-confirm-password-toggle-icon"
				>
					{{
						showConfirmPassword ? "olho_inativo_outline2" : "visivel_outline"
					}}
				</ids-icon>
			</ids-form-field>

			<div
				*ngIf="form.errors?.noMatchedPassword"
				class="register-password-container__error"
				data-testid="register-password-error"
			>
				<ids-icon size="S">aviso_outline</ids-icon>
				<span>por favor informe assinaturas eletrônicas idênticas</span>
			</div>

			<button
				class="ids-tooltip-wrapper register-password-container__tooltip"
				aria-label="tooltip middle start"
				type="button"
			>
				<ids-icon>informacao_outline</ids-icon>
				<span>requisitos para criação de assinatura</span>

				<ids-tooltip aria-hidden="true">
					<ul class="register-password-container__rules">
						<li>6 caracteres númericos</li>
						<li>Não ter caracteres sequenciais</li>
						<li>Não ser igual as últimas 5 assinaturas</li>
						<!-- <li>Não possuir combinação de data de nascimento</li> -->
					</ul>
				</ids-tooltip>
			</button>

			<div class="register-password-container__loading">
				<ids-loading *ngIf="isSubmitting; else submitBtn"></ids-loading>
			</div>

			<ng-template #submitBtn>
				<button
					a11yLabel="continuar"
					idsMainButton
					[disabled]="!form.valid || isSubmitting"
					class="register-password-container__submit"
					id="register-password-submit"
					type="submit"
				>
					<div>continuar</div>
				</button>
			</ng-template>
		</form>
	</div>
</section>
