import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

export class IaaSErrorResponse {
	code: string;
	message: string;
	status: number;
}

const friendlyErrors: Record<string, any> = {
	gtw_4xx_internal_error: 'Verifique se todos os dados foram enviados corretamente.',
	DEFAULT: 'Erro inesperado. Por favor reinicie a operação.',
};

@Injectable()
export class ErrorHandlingService {
	constructor() {}

	getErrorMessage(
		error?: HttpErrorResponse,
		resource?: string,
		resourceValue?: string
	): string {
		if (!error) return friendlyErrors['DEFAULT'];

		const errorResponse: IaaSErrorResponse = {
			...error?.error,
			status: error?.status,
		};

		if (
			!errorResponse || 
			!errorResponse?.message ||
			errorResponse?.status == 500 ||
			errorResponse?.code == 'unknown'
		)
			return friendlyErrors['DEFAULT'];

		if (resource && resourceValue && errorResponse?.status == 404)
			return `Não encontramos ${resource?.toLowerCase()} com valor ${resourceValue}.`;

		if (errorResponse?.code?.includes('CIAM'))
			return friendlyErrors['DEFAULT'];

		return (
			friendlyErrors[errorResponse.code?.toLowerCase()] ?? errorResponse.message
		);
	}
}
