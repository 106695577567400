import { ModuleWithProviders, NgModule } from '@angular/core';
import { CharonService } from '@quickweb/connect-angular';

import { SEAHttpClientFactory } from './factories/sea-http-client.factory';
import { SEAHttpClient } from './sea-http-client.service';
import { SEA_GATEWAY_CONFIG, CHARON_ID } from './injection-tokens';
import { SEAGatewayConfig } from './types';
import { HttpClient } from '@angular/common/http';
import { FeatureToggleService } from '@/app/services/feature-toggle/feature-toggle.service';

interface SEAHttpConfig {
	SEAGateway: SEAGatewayConfig;
	charonId: string;
}

@NgModule()
export class SeaHTTPModule {
	static forRoot(config: SEAHttpConfig): ModuleWithProviders<SeaHTTPModule> {
		return {
			ngModule: SeaHTTPModule,
			providers: [
				// {
				// 	provide: HTTP_INTERCEPTORS,
				// 	useClass: SEAAuthInterceptor,
				// 	multi: true,
				// },
				{
					provide: SEA_GATEWAY_CONFIG,
					useValue: config.SEAGateway,
				},
				{
					provide: CHARON_ID,
					useValue: config.charonId,
				},
				{
					provide: SEAHttpClient,
					useFactory: SEAHttpClientFactory,
					deps: [
						HttpClient,
						FeatureToggleService,
						CharonService,
						SEA_GATEWAY_CONFIG,
						CHARON_ID,
					],
				},
			],
		};
	}
}
