import { NgModule } from '@angular/core';
import { RegisterPasswordComponent } from './register-password/register-password.component';
import {
	IdsAlertModule,
	IdsFormFieldModule,
	IdsIconModule,
	IdsInputModule,
	IdsLoadingModule,
	IdsMainButtonModule,
	IdsTooltipModule,
} from '@ids/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
	declarations: [RegisterPasswordComponent],
	imports: [
		CommonModule,
		NgOtpInputModule,
		IdsFormFieldModule,
		ReactiveFormsModule,
		IdsInputModule,
		IdsMainButtonModule,
		IdsIconModule,
		IdsTooltipModule,
		IdsLoadingModule,
		IdsAlertModule,
	],
	providers: [],
})
export class PagesModule {}
