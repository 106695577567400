export const environment = {
	production: false,
	bff: {
		baseUrl: 'http://localhost:3000',
	},
	charon: {
		id: 'qvjzobwp',
		entrypoint: 'https://apicd.hom.cloud.itau.com.br/charon/qvjzobwp',
		cryptographyUrl: `https://caronte.cyberdefense.hom.aws.cloud.ihf/iske`,
		itauApiKey: 'IOeVWbJJ70aZCnLXBigo',
	},
	featureToggles: {
		charon: true,
	},
};
