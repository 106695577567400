import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';

@NgModule({
	imports: [],
	declarations: [HeaderComponent],
	providers: [],
	exports: [HeaderComponent],
})
export class HeaderModule {}
